import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Banner from '../components/Banner';
import Layout from '../components/layout';
import SEO from '../components/Seo';
import PrivateRoute from '../components/auth/protected';
import { getAllRoadmaps } from '../utils/api';
import { updateChecklist } from '../state/login/action';
import { setCurrentMilestone } from '../state/create/answer/action';
import { changeCurrentMilestone } from '../state/create/answer/thunks';
import { toast } from 'react-toastify';
import { graphql, useStaticQuery } from 'gatsby';
import UpdateAndConnectShopifyModal from '../components/UpdateAndConnectShopifyModal';

import {
  Button,
  Carousel,
  Form,
  Overlay,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {
  findIfFinished,
  findMilestonesQuestions,
  getUserAnswers,
} from '../utils/helpers';
import { navigate } from '@reach/router';
import WindowWidth from '../components/WindowWidth';
import ButtonLink from '../components/ButtonLink';

const MilestoneItem = (props) => {
  const {
    item,
    idx,
    setToggle,
    toggle,
    updateCheckListState,
    findNextById,
    isMobileScreen,
    updateCurrentMilestone,
    currentMilestone,
    usersChecklist,
    profile,
    roadmap,
  } = props;

  const [notes, setNotes] = useState('');
  const [editedQuestions, setEditedQuestions] = useState(null);

  let filteredChecklist =
    usersChecklist && usersChecklist.sort((a, b) => a.position - b.position);

  const handleCustomChange = async (e, item) => {
    const editedMilestoneQuestions = filteredChecklist.map((question, idx) => {
      if (item.id === question.id) {
        const editedQuestion = question;
        editedQuestion.checked = e.target.checked;
        return editedQuestion;
      }
      return question;
    });

    let data = {
      question: item.id,
      roadmap: roadmap.id,
      milestone: currentMilestone.id,
      customer: profile.erp_id,
      notes: notes ? notes : '',
      type: e.target.checked == true ? 'checked' : 'unchecked',
    };

    setEditedQuestions({ data, editedMilestoneQuestions });

    toast.success(
      `The item: '${item.question}' has been ${e.target.checked ? 'completed' : 'unchecked'
      } !`,
      {
        className: 'bg-primary text-white',
      }
    );

    if (!findMilestonesQuestions(currentMilestone, filteredChecklist)) {
      updateCurrentMilestone(data, editedMilestoneQuestions);
      updateCheckListState(editedMilestoneQuestions);
      setNotes('');
    } else {
      updateCurrentMilestone(data, editedMilestoneQuestions);
      updateCheckListState(editedMilestoneQuestions);
      setNotes('');
      toast.success(`The milestone has been completed!`, {
        className: 'bg-primary text-white',
      });
    }
  };

  return (
    <>
      <div className='item'>
        <div className='item-content'>
          <div className='item-title' onClick={() => findNextById(item)}>{`${idx + 1
            }. ${item.name}`}</div>
          {!isMobileScreen && (
            <div className='item-image'>
              <img src={item.image ? item.image : null} />
            </div>
          )}
        </div>
        <div className='item-checkList'>
          <div
            className='link-click'
            data-toggle='collapse'
            data-target={`#link${item.id}`}
            role='button'
            aria-expanded='false'
            aria-controls={`link${item.id}`}
            onClick={() => {
              setToggle({
                id: item.id,
                toggle: !toggle.toggle,
              });
            }}
          >
            <span className='check-title'>View Checklist</span>
            {toggle.id !== item.id || !toggle.toggle ? (
              <i className='fas fa-chevron-down icons' />
            ) : (
              <i className='fas fa-chevron-up icons' />
            )}
          </div>
          <div className='collapse' id={`link${item.id}`}>
            <div className='link-content'>
              {filteredChecklist.map((filterItem) => {
                return item.question.map((qs, idx) => {
                  if (filterItem.id == qs.id) {
                    return (
                      <a key={idx}>
                        <input
                          className='myinput'
                          type='checkbox'
                          name={`check-item-${qs.id}`}
                          checked={filterItem.checked}
                          onChange={(e) => {
                            handleCustomChange(e, qs);
                          }}
                          disabled={item.id > currentMilestone.id}
                        />
                        <span
                          className={
                            filterItem.checked ? 'quest-checked' : 'quest'
                          }
                        >
                          {filterItem.question}
                        </span>
                      </a>
                    );
                  }
                });
              })}
            </div>
            <div className='mls-notes'>
              <Form.Label>Notes</Form.Label>
              <OverlayTrigger
                overlay={
                  <Tooltip id='tooltip-bottom'>
                    You can leave a note when checking an item, or when
                    completing the milestone!
                  </Tooltip>
                }
              >
                <Form.Control
                  className='checklist-notes'
                  as='textarea'
                  name='notes'
                  placeholder='Leave a note here'
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                  value={notes}
                  disabled={item.id < currentMilestone.id}
                />
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Step = (props) => {
  const { items, currentStep } = props;
  return (
    <div className='stepper-wrapper'>
      {items.map((item, idx) => {
        return (
          <div
            key={idx}
            className={
              currentStep && currentStep.id === item.id
                ? 'stepper-item active'
                : currentStep && currentStep.id > item.id
                  ? 'stepper-item completed'
                  : 'stepper-item'
            }
          >
            <div className='step-counter'>
              {currentStep && currentStep.id > item.id && (
                <i className='fas fa-check' />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const VerticalStep = (props) => {
  const {
    milestones,
    setToggle,
    toggle,
    setActive,
    updateCheckListState,
    updateCurrentMilestone,
    currentMilestone,
    roadmap,
    profile,
    usersChecklist,
    setCurrentMilestone,
    heightStyle,
  } = props;

  const findNextById = (clicked) => {
    const found = milestones.find((item) => {
      if (item.id === clicked.id) {
        return item;
      }
    });
    if (found) {
      setActive(found);
    }
  };

  return (
    <ul className='StepProgress'>
      {milestones.map((item, idx) => {
        return (
          <li
            className={`StepProgress-item ${currentMilestone && currentMilestone.id === item.id
              ? `current`
              : currentMilestone && currentMilestone.id > item.id
                ? 'is-done'
                : ''
              }`}
            key={idx}
          >
            <MilestoneItem
              idx={idx}
              item={item}
              setToggle={setToggle}
              toggle={toggle}
              findNextById={findNextById}
              isMobileScreen={false}
              usersChecklist={usersChecklist}
              updateCheckListState={updateCheckListState}
              roadmap={roadmap}
              profile={profile}
              currentMilestone={currentMilestone}
              updateCurrentMilestone={updateCurrentMilestone}
              setCurrentMilestone={setCurrentMilestone}
            />
          </li>
        );
      })}
    </ul>
  );
};

const Dashboard = (props) => {
  const [index, setIndex] = useState(0);
  const [toggle, setToggle] = useState({ id: '', toggle: false });
  const [activeMilestone, setActive] = useState(null);
  const [milestones, setMilestones] = useState(null);
  const [roadmap, setRoadmap] = useState(null);

  const target = useRef(null);
  const [show, setShow] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const windowWidth = WindowWidth();

  const rebrandDashboardData = useStaticQuery(graphql`
    {
      wordpressAcfDashboard(wordpress_id: { eq: 762 }) {
        acf {
          hero_section {
            title
            image {
              source_url
            }
            button {
              title
              internal_link
              external_link
              custom_link
            }
          }
          my_dashboard {
            title
            description
            image {
              source_url
            }
            image_title
            link_section {
              title
              link
            }
          }
          dashboard_cards {
            image {
              source_url
            }
            description
            title
            description_2
            link {
              link_title
              link_url
            }
          }
        }
      }
    }
  `).wordpressAcfDashboard;


  const hero_section = rebrandDashboardData.acf.hero_section[0];
  const my_dashboard = rebrandDashboardData.acf.my_dashboard[0];
  const dashboard_cards = rebrandDashboardData.acf.dashboard_cards;

  const fetchAllRoadmaps = async () => {
    const data = await getAllRoadmaps(props.authToken);

    const defaultRoadmap = data
      ? data.find(
        (item) =>
          item.name.replaceAll(' ', '').toLowerCase() ===
          'roadmaptodropshippedjewelry'
      )
      : null;

    const answ = getUserAnswers(props.profile);
    const found = data
      ? data.find((item) => findCustomerRoadmap(answ, item) === true)
      : null;

    let finalRoadmap = found ? found : defaultRoadmap;
    setRoadmap(finalRoadmap);

    if (finalRoadmap) {
      const newMilestones = finalRoadmap.milestones
        .sort((a, b) => a.id - b.id)
        .map((item, idx) => {
          return {
            ...item,
            position: idx + 1,
          };
        });

      setMilestones(newMilestones ? newMilestones : null);
      setActive(newMilestones[0]);
    }
  };

  const findCustomerRoadmap = (answers, roadmap) => {
    let nrOfAnswers = 0;
    const parsed = roadmap.answers ? JSON.parse(roadmap.answers) : [];
    answers.map((item) => {
      parsed.map((ans) => {
        if (Number(item.answerId) === Number(ans.id)) {
          nrOfAnswers++;
        }
      });
    });

    if (nrOfAnswers === parsed.length) {
      return true;
    }
    return false;
  };

  const findNext = (current, milestones) => {
    return (
      milestones &&
      milestones.find((item) => {
        if (item.position === current + 1) {
          return item;
        }
      })
    );
  };

  let usersChecklist = [];

  if (props.profile) {
    if (props.profile.questions) {
      props.profile.questions.length === 0
        ? milestones &&
        milestones.map((item) => {
          item.question.map((quest) => {
            usersChecklist.push(quest);
          });
        })
        : (usersChecklist = props.profile.questions);
    }
  }

  useEffect(() => {
    const active =
      roadmap &&
      roadmap.milestones.find((item, idx) => {
        if (findMilestonesQuestions(item, usersChecklist) === false) {
          setIndex(idx);
          return item;
        }
      });
    props.setCurrentMilestone(active);
  }, [props.profile, roadmap, usersChecklist]);

  const handleSelect = (selectedIndex, e) => {
    const found = findNext(selectedIndex, milestones);
    if (found) {
      setActive(found);
    }
    setIndex(selectedIndex);
  };

  useEffect(() => {
    fetchAllRoadmaps();
  }, []);

  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  const heightStyle = {
    '--height': !toggle.toggle && toggle.id === '' ? `85%` : `0`,
  };

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [toggle.toggle]);

  const membership = props.profile
    ? props.profile.membership
      ? props.profile.membership.metadata
        ? props.profile.membership.metadata.nickname
        : props.profile.membership.name
      : null
    : null;

  let userQuestions = [];
  if (props.profile) {
    if (props.profile.questions) {
      userQuestions = props.profile.questions;
    }
  }

  // const roadmapFinished = findIfFinished(userQuestions, usersChecklist);

  const hasShopify = () => {
    if (props.profile.shopifyToken && props.profile.shopifyUrl) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (props.isFirstTimeLogin) {
      setTimeout(() => {
        setOpenModal(true);
      }, 3000);
    }

    if (props.profile) {
      if (props.profile.remind) {
        if (props.profile.remind === 'no') {
          setOpenModal(false);
        } else {
          if (membership.toLowerCase() === 'standard' && !hasShopify()) {
            if (props.profile.remindTime) {
              const remindDate = new Date(props.profile.remindTime);
              const moreThan7Days = new Date(
                remindDate.setDate(remindDate.getDate() + 7)
              );
              const remind = new Date() >= moreThan7Days;
              if (remind) {
                setTimeout(() => {
                  setOpenModal(true);
                }, 2500);
              }
            } else {
              setTimeout(() => {
                setOpenModal(true);
              }, 2500);
            }
          }
        }
      } else if (!hasShopify()) {
        setTimeout(() => {
          setOpenModal(true);
        }, 3000);
      } else {
        setTimeout(() => {
          setOpenModal(false);
        }, 3000);
      }
    }
  }, [props.profile, membership, props.isFirstTimeLogin]);

  return (
    <>
      <PrivateRoute>
        {props.profile && !props.profile.answers ? <Banner /> : null}
        <Layout
          light_header={undefined}
          sit_on_top
          className='home'
          noAnswers={props.profile && !props.profile.answers ? true : false}
        >
          <UpdateAndConnectShopifyModal
            show={openModal}
            profile={props.profile}
            handleClose={() => setOpenModal(false)}
          />
          <SEO title='Dashboard' />
          <div className='dashboard-container'>
            <div className='hero-dashboard-container'>
              <div className='text-button-container'>
                <span>
                  Hello {props.profile ? props.profile.firstName : ''}{' '}
                  {props.profile ? props.profile.lastName : ''},{' '}
                  {hero_section.title}
                </span>
                <div className='buttons-section'>
                  <ButtonLink
                    {...hero_section.button[0]}
                    className={'start-design-btn'}
                  />
                  <Button
                    className='view-profile-btn'
                    onClick={() => navigate('/profile/edit')}
                  >
                    View or Edit My Profile
                    <i
                      style={{ marginLeft: '10px' }}
                      className='fas fa-angle-right'
                    />
                  </Button>
                </div>
              </div>
              <div className='image-container'>
                <img src={hero_section.image.source_url} />
              </div>
            </div>
            <div className='order-managment-section'>
              <div className='section-title'>
                <span className='title'>{my_dashboard.title}</span>
                <span className='link'>
                  <a
                    href='/'
                    target='_blank'
                    dangerouslySetInnerHTML={{
                      __html: my_dashboard.description,
                    }}
                  />
                </span>
              </div>
              <div className='section-body'>
                <div className='manage-image-section'>
                  <div className='image'>
                    <img src={my_dashboard.image.source_url} />
                  </div>
                  <div className='image-text'>
                    <span>{my_dashboard.image_title}</span>
                  </div>
                </div>
                <div className='manage-link'>
                  <div className='link-1'>
                    <span>{my_dashboard.link_section[0].title}</span>
                  </div>
                  <div
                    className='link-2'
                    onClick={() => {
                      navigate('/orders');
                    }}
                  >
                    {my_dashboard.link_section[0].link}
                    <i
                      style={{ marginLeft: '10px' }}
                      className='fas fa-angle-right'
                    />
                  </div>
                </div>
              </div>
              <div className='section-footer'>
                {dashboard_cards.map((card, index) => (
                  <div className='manage-card' key={index}>
                    <div className='manage-card-header'>
                      <img src={card.image.source_url} />
                    </div>
                    <div className='manage-card-body'>
                      <span
                        className='body-hashtag'
                        dangerouslySetInnerHTML={{ __html: card.description }}
                      />
                      <span
                        className='body-title'
                        dangerouslySetInnerHTML={{ __html: card.title }}
                      />
                      <span
                        className='body-content'
                        dangerouslySetInnerHTML={{ __html: card.description_2 }}
                      />
                    </div>
                    <div className='manage-card-footer'>
                      <a href={card.link[0].link_url} target='_blank'>
                        {card.link[0].link_title}
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='roadmap-section' id='roadmap'>
              <div
                className='section-title'
                ref={target}
                onMouseEnter={() => {
                  if (membership === 'Standard') {
                    setShow(true);
                  }
                }}
                onMouseLeave={() => {
                  if (membership === 'Standard') {
                    setShow(false);
                  }
                }}
              >
                <span>My Roadmap</span>
              </div>
              {membership === 'Standard' && (
                <Overlay
                  target={target.current}
                  show={membership === 'Standard' ? show : false}
                  placement='top'
                >
                  {(props) => (
                    <Tooltip id='overlay-example' {...props}>
                      Upgrade your membership account to access the roadmap!
                    </Tooltip>
                  )}
                </Overlay>
              )}
              <div
                className={`section-content`}
                ref={ref}
                style={
                  (props.profile && !props.profile.answers) ||
                    membership === 'Standard'
                    ? { filter: 'blur(8px)', pointerEvents: 'none' }
                    : null
                }
              >
                <div className='milestone-help'>
                  <div className='milestone-help-content'>
                    <span className='title'>
                      {props.currentMilestone
                        ? props.currentMilestone.name
                        : activeMilestone
                          ? activeMilestone.name
                          : ''}
                    </span>
                    <span className='milestione-help-description'>
                      {props.currentMilestone
                        ? props.currentMilestone.description
                        : activeMilestone
                          ? activeMilestone.description
                          : ''}
                    </span>
                    <Button
                      className='help-button'
                      onClick={() => {
                        window &&
                          window.open(
                            `https://share.hsforms.com/1njNk7mDQQ6SxA7fVSGHCqQ3kh5b`,
                            '_blank'
                          );
                      }}
                    >
                      Get help on this milestone
                    </Button>
                  </div>
                </div>
                <div className='roadmap-content'>
                  <div className='header'>
                    <span className='title'>{roadmap && roadmap.name}</span>
                    <span className='content'>
                      {roadmap && roadmap.description}
                    </span>
                  </div>
                  <div className='roadmap-milestones'>
                    {windowWidth <= 991 ? (
                      <Step
                        items={milestones ? milestones : []}
                        currentStep={props.currentMilestone}
                      />
                    ) : (
                      <div className='progressContainer'>
                        <VerticalStep
                          milestones={milestones ? milestones : []}
                          setToggle={setToggle}
                          toggle={toggle}
                          setActive={setActive}
                          currentMilestone={
                            props.currentMilestone
                              ? props.currentMilestone
                              : activeMilestone && activeMilestone
                          }
                          updateCurrentMilestone={props.updateCurrentMilestone}
                          updateCheckListState={props.updateCheckListState}
                          usersChecklist={usersChecklist}
                          roadmap={roadmap}
                          profile={props.profile}
                          setCurrentMilestone={props.setCurrentMilestone}
                          heightStyle={heightStyle}
                        />
                      </div>
                    )}
                    <div className='carousel-content'>
                      {windowWidth <= 991 && (
                        <Carousel
                          activeIndex={index}
                          onSelect={handleSelect}
                          interval={null}
                          variant={'dark'}
                          prevIcon={
                            <i
                              className='fas fa-arrow-left'
                              style={{ fontSize: '16px', color: 'black' }}
                            />
                          }
                          nextIcon={
                            <i
                              className='fas fa-arrow-right'
                              style={{ fontSize: '16px', color: 'black' }}
                            />
                          }
                        >
                          {milestones &&
                            milestones.map((item, idx) => {
                              return (
                                <Carousel.Item
                                  className='carousel-item'
                                  key={idx}
                                  style={{ marginLeft: '10px' }}
                                >
                                  <MilestoneItem
                                    idx={idx}
                                    item={item}
                                    setToggle={setToggle}
                                    toggle={toggle}
                                    isMobileScreen={windowWidth < 970}
                                    updateCurrentMilestone={
                                      props.updateCurrentMilestone
                                    }
                                    currentMilestone={props.currentMilestone}
                                    updateCheckListState={
                                      props.updateCheckListState
                                    }
                                    usersChecklist={usersChecklist}
                                    profile={props.profile}
                                    roadmap={roadmap}
                                    setCurrentMilestone={
                                      props.setCurrentMilestone
                                    }
                                  />
                                </Carousel.Item>
                              );
                            })}
                        </Carousel>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </PrivateRoute>
    </>
  );
};

const mapStateToProps = (state) => ({
  itemConfigs: state.itemConfigs.configs,
  profile: state.login.auth && state.login.auth.profile,
  authToken: state.login.auth && state.login.auth.auth.accessToken,
  roadmap: state.create.answer && state.create.answer.roadmap,
  currentMilestone: state.create.answer && state.create.answer.currentMilestone,
  isFirstTimeLogin:
    state.login &&
    state.login.signUpWorkflow &&
    state.login.signUpWorkflow.isFirstTimeLogin,
});

const mapDispatchToProps = (dispatch) => ({
  updateCurrentMilestone: (data, questions) =>
    dispatch(changeCurrentMilestone(data, questions)),
  setCurrentMilestone: (data) => dispatch(setCurrentMilestone(data)),
  updateCheckListState: (data) => dispatch(updateChecklist(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
